
 // @ is an alias to /src
import Navigation from '@/components/partials/Navigation.vue';
import Aside from '@/components/partials/Aside.vue';
import Footer from '@/components/partials/Footer.vue';
import { defineComponent, ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: "CreateLesson",
  components: {
    Navigation,
    Aside,
    Footer
  },
  setup() {
    const store = useStore();
    const links = reactive([
      {
        name: "Create Lesson",
        url: "lesson/create"
      },
    ]);
    const levels = reactive(["JS1", "JS2", "JS3", "SS1", "SS2", "SS3"]);
    const lesson = reactive({
      name: "Test Exam",
      levels: [],
    });
    const name = ref("Lesson");
    const nav = computed(() => store.getters.nav);

    return { name, nav, links, levels, lesson };
  },
});
